import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/SecuB-logo.svg";
import WebService from "../../Services/WebService";
import PageLoader from "../Common/PageLoader/PageLoader";
import "./loader.scss";
const VerifyEmail = () => {
  let params = useParams();
  let account_verification_id = params.url;
  let navigate = useNavigate();

  useEffect(() => {

    if (account_verification_id) {
      handleVerifyEmail();
    }
  }, []);
  const handleVerifyEmail = () => {
    return WebService.BeforLoginPostAPI({
      action: `auth/account/verify-link`,
      body: { token: account_verification_id },
    })
      .then((res: any) => {
        localStorage.setItem("access_token", res?.token);
        navigate("/email-verification-success");
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <>
      <Container fluid>
        <div className="p-4">
          <img src={Logo} width="145" className="mb-3" alt="Logo" />
        </div>
      </Container>
      <div className="container">
        <div className="verify-email-container" style={{ height: "auto" }}>
          <div className="page-content">
            <div className="loader-container">
              <PageLoader />
            </div>
            <div className="loading-message">Verifying...Please wait...</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VerifyEmail;
