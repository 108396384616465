import { Link } from 'react-router-dom'; 
import Logo from '../../assets/images/SecuB-logo.svg'
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { PiUserSquare } from "react-icons/pi";
import { RiArchiveDrawerLine } from "react-icons/ri";
import { TbMessageChatbot } from "react-icons/tb";
import { TbUserCheck } from "react-icons/tb";
import { GoClock } from "react-icons/go";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { IoIosStarOutline } from "react-icons/io";
import { TfiHeadphoneAlt } from "react-icons/tfi";

import { MdSpaceDashboard, MdCategory, MdLocalGroceryStore, MdLayers, MdGroup, MdCoPresent, MdManageAccounts, MdOutlinePublicOff,MdGppGood } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";

const VerticalMenu = () => {
    return (
        <>
            <div id="vertical_menu" className="verticle-menu">
                <div className='text-center pb-3'>
                    <img src={Logo} width={118}  alt='' />
                </div>
                <div className="menu-list">
                <Link id="t-1" to={'/dashboard'} className="menu-item"> <MdOutlineSpaceDashboard className="menu-icon" /> <span className='nav-text'>Dashboard</span></Link>
                    <Link id="t-1" to={'/first-login'} className="menu-item"> <PiUserSquare className="menu-icon" /> <span className='nav-text'>First Login </span></Link>
                    <Link id="t-1" to={'/q-and-a'} className="menu-item"> <TbMessageCircleQuestion className="menu-icon" /> <span className='nav-text'>Q&A</span></Link>
                    <Link id="t-1" to={'/files'} className="menu-item"> <RiArchiveDrawerLine className="menu-icon" /> <span className='nav-text'>Files</span></Link>
                    <Link id="t-1" to={'/chat-bot'} className="menu-item"> <TbMessageChatbot className="menu-icon" /> <span className='nav-text'>Chat bot</span></Link>
                    <Link id="t-1" to={'/access-mangement'} className="menu-item"> <TbUserCheck className="menu-icon" /> <span className='nav-text'>Access Management</span></Link>
                    <Link id="t-1" to={'/track-changes'} className="menu-item"> <GoClock className="menu-icon" /> <span className='nav-text'>Track Changes</span></Link>
                    <Link id="t-1" to={'/whats-new'} className="menu-item"> <IoIosStarOutline className="menu-icon" /> <span className='nav-text'>What's New !</span></Link>
                    <Link id="t-1" to={'/support'} className="menu-item"> <TfiHeadphoneAlt className="menu-icon" /> <span className='nav-text'>Help & Support</span></Link>
                </div>
            </div>
        </>
    )
}
export default VerticalMenu;