
export const ADD_PROFILE_DETAILS='ADD_PROFILE_DETAILS'
export const ADD_SUBSCRIPTION_DETAILS='ADD_SUBSCRIPTION_DETAILS'


export const profileSettings = (payload:any) => ({
  type:ADD_PROFILE_DETAILS,
  payload: payload,
});

export const SubscriptionDetails = (payload:any) => ({
  type:ADD_SUBSCRIPTION_DETAILS,
  payload: payload,
});
