import { ADD_PROFILE_DETAILS, ADD_SUBSCRIPTION_DETAILS } from './action';

const initialState = {
profile_setting:{},
subscription_details:{}
};

export const profileReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case ADD_PROFILE_DETAILS:
      return {
        ...state,
        profile_setting: action.payload,
      }
     case ADD_SUBSCRIPTION_DETAILS:
         return {
           ...state,
           subscription_details: action.payload,
         };
    default:
      return state;
  }
};
